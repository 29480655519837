@font-face {
    font-family: myFirstFont;
    src: url(./font.ttf);
  }

* {
    font-family: myFirstFont;

}

/* .ant-menu-item {
  margin: -20px;
} */

/*
.ant-select-selection-search {
  background-color: green;
}

.red-menu:hover {
  background-color: red;
}

.ant-menu-item-selected {
  color: red !important;
   border-bottom: 2px solid red !important;
}
*/