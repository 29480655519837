a {
    color:#A80042
}
.ant-menu-item{
    font-size: 12px !important;
    padding: 0px 8px !important
}
.ant-tabs-small > .ant-tabs-nav .ant-tabs-tab{
    margin:3px !important;
}
/* .ant-tabs{
    background-color:#A70042;
    color:#fff;
} */
.ant-tabs-nav{
    margin-bottom: 0px;
}
.ant-tabs-top > .ant-tabs-nav, .ant-tabs-bottom > .ant-tabs-nav, .ant-tabs-top > div > .ant-tabs-nav, .ant-tabs-bottom > div > .ant-tabs-nav {
    margin: 0;
}